.workflow-block {
  display: flex;
  padding-left: 15px;
}

.workflow-header {
  min-width: 10rem;
  .title {
    font-size: 18px;
    display: block;
  }
}

.workflow-content {
  flex-grow: 1;
  padding-left: 0.8rem;
  border-left: 1px solid #eee;
  border-left-width: 0.25rem;
}