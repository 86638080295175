.table-bordered > thead > tr > th, table th, table.dataTable thead .sorting, table.dataTable thead .sorting_asc, table.dataTable thead .sorting_desc, table.dataTable thead .sorting_asc_disabled, table.dataTable thead .sorting_desc_disabled {
  background: #F5F5F5;
  font-size: 14px;
  border-bottom: 0 solid #E2E2E2;
}

table.dataTable thead .sorting:after {
  color: #2A7AAF;
}

table.dataTable thead > tr > th {
  padding-left: 8px;
  padding-right: 8px;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background: none;
}

.label {
  border-radius: 0;
  text-transform: uppercase;
  font-size: 11px;
}

.label-success {
  background: #6FAF2A;
}

.label-warning {
  background: #FF852C;
}

.table > tbody > tr > td {
  vertical-align: middle;
}

.dataTables_info {
  font-size: 14px;
  color: #8E8F90;
  padding: 0 0 0 10px;
}

.popover {
  max-width: 800px;
}

.date-time-column {
  width: 14%;
}

.event-column {
  width: 14%;
}

.event-detail-column {
  width: 28%;
}

.event-status-change-reason-column {
  width: 44%;
}

.subscription-status-container > * {
  padding-right: 8px;
}

.fa.red {
  color: #d9534f;
}

.fa.green {
  color: #6FAF2A;
}

.fa.orange {
  color: orange;
}

.fa.gray {
  color: #5b6265;
}