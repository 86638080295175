@media (min-width: 768px) {
    #side-menu-bottom {
        position: fixed;
        bottom: 0;
    }

    .row-filter {
        margin-bottom: 10px;
    }

    .pagination {
        margin: 0;
    }
}

/* Add an asterisk after required fields */
.control-label.required:after {
    content: " *";
}

button.list-group-item {
    text-align: center;
}

div.training_occurrence{
    display:none;
}

div.training_center_holder {
    display: none;
}

div.row.home {
    margin-top: 75px;
}

div.row.home div.title {
    margin-bottom: 10px;
}

div.row.home div.title h1 {
    color: #2a7aaf;
    font-weight: bold;
    font-size: 30px;
}

div.row.home div.well h3 {
    margin-bottom: 25px;
    font-weight: bold;
    font-size: 21px;
}

div.row.home div.well p {
    margin-bottom: 20px;
}

div.row.home div.well {
    box-shadow: none;
    border-radius: 0;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 6px;
    padding-bottom: 20px;
}

div.row.home div.well a {
    font-weight: bold;
}

.list-group-item.btn {
    margin-bottom: 1px;
}

.badge.red {
    background: #C9302C;
}

.badge.orange {
    background: #F0AD4E;
}

.badge.green {
    background: #6faf2a;
}

.badge.grey {
    background: #ddd;
}



div.comment_head {
    font-weight: bold;
}

/* Movement buttons */
div.movement div.btn-group {
    margin-bottom: 20px;
    width: 100%;
}

div.movement div.btn-group a {
    width: 50%;
    display: inline-block;
}

/* Training content description */
textarea#training_nextVersion_description {
    display:none;
}

/* Well border colors */
.well.danger {
    border: 1px solid #A94442;
}
.well.warning {
    border: 1px solid #8A6D3B;
}

/* Clickable */
.clickable {
    cursor: pointer;
}

/* VICH */
div.btn-toolbar.vich {
    float: left;
    margin-left: 0;
}

div.btn-toolbar.vich .btn {
    padding: 6px 12px;
}

div.btn-toolbar.vich div.checkbox {
    display: none;
}

div.test_occurrence.btn-toolbar .btn {
    padding: 0 11px 0 0;
}

div.test_occurrence.btn-toolbar .btn-success i.fa-user {
    font-size: 18px;
    padding: 17px 11px 18px;
    background: #7fc434;
    margin: 0 10px 0 0;
}

.table-striped > thead > tr > th,
.table-striped > thead > tr > td {
    background-color: white !important;
}
