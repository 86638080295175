//* Root typography */

body {
  line-height: $base-line-height;
  word-wrap: break-word;
  hyphens: auto;
  font-family: "aktiv-grotesk", sans-serif;
  font-weight: 300;
  font-size: 16px;
  color: #08151D;
  -webkit-font-smoothing: antialiased;
}

html {
  font-size: 100%;
}

