@media (min-width: 768px) {
  .sidebar {
    margin-top: 135px;
    border: none;
  }
}

.sidebar ul li {
  border: 1px solid #E1E1E1;
  border-top: none;
  font-size: 17px;

  &:first-child {
    border-top: 1px solid #E1E1E1;
  }

  a {
    padding: 21px 15px 20px;
    background: none;
    color: #2A7AAF;
    font-weight: bold;

    i {
      color: #D5D5D5;
      margin: 0 5px 0 0;
    }

    &.active {
      background-color: #2A7AAF;
      color: #FFF;

      i {
        color: #FFF;
      }
    }

    &:hover {
      color: #08151D;
      background-color: transparent;
    }
  }
}

#side-menu-bottom {
  li {
    border: none;
  }
}

