// Spacing utility classes similar to Bootstrap 4
// Variables
$spacer: 1rem !default;
$spacers: (
        0: 0,
        1: ($spacer * 0.25),  // 0.25rem
        2: ($spacer * 0.5),   // 0.5rem
        3: $spacer,           // 1rem
        4: ($spacer * 1.5),   // 1.5rem
        5: ($spacer * 2),   // 2rem
) !default;

// Margin and padding
@each $prop, $abbrev in (margin: m, padding: p) {
  @each $size, $length in $spacers {
    .#{$abbrev}-#{$size} {
      #{$prop}: $length !important;
    }

    // Top
    .#{$abbrev}t-#{$size} {
      #{$prop}-top: $length !important;
    }

    // Right
    .#{$abbrev}r-#{$size} {
      #{$prop}-right: $length !important;
    }

    // Bottom
    .#{$abbrev}b-#{$size} {
      #{$prop}-bottom: $length !important;
    }

    // Left
    .#{$abbrev}l-#{$size} {
      #{$prop}-left: $length !important;
    }

    // X axis (left and right)
    .#{$abbrev}x-#{$size} {
      #{$prop}-right: $length !important;
      #{$prop}-left: $length !important;
    }

    // Y axis (top and bottom)
    .#{$abbrev}y-#{$size} {
      #{$prop}-top: $length !important;
      #{$prop}-bottom: $length !important;
    }
  }
}

// Auto margins
.m-auto { margin: auto !important; }
.mt-auto { margin-top: auto !important; }
.mr-auto { margin-right: auto !important; }
.mb-auto { margin-bottom: auto !important; }
.ml-auto { margin-left: auto !important; }
.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}
.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}