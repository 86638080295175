// Make select themable:
select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 1.75rem;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right .75rem center/8px 10px;
}

.form-control {
  border-radius: 0;
  box-shadow: none;
  border-color: #B1B1B1;
}

label {
  color: #08151D;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 13px;
}

.checkbox label {
  text-transform: none;
  font-size: 14px;
}

div.dataTables_processing {
  top: 39px;
  margin-top: 0;
  color: #FFF;
  font-size: 1.2em;
  background: rgba(0, 0, 0, .86);
}

.file-input {
  float: left;
  max-width: calc(100% - 92px);
  width: 100%;
}

.spinner {
  position: absolute;right: 25px;top: 32px;
}

.filters-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -5px;
}

.filter {
  padding: 0 5px;
  margin-bottom: 10px;

  label {
    margin: 0;
    text-transform: none;
  }

  .form-control {
    @include up(768) {
      max-width: 200px;
    }
  }
}

.btn-toolbar {
  display: flex;
  margin: 0 -5px;

  > .btn {
    margin: 0 5px 10px;
  }
}
