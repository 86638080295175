.navbar-static-top {
  background: #1F272B;
}

.navbar-top-links {
  font-weight: 500;
  a {
    font-size: 16px;
    color: #FFF;

    &:hover {
      color: #08151D;
    }
  }

  .fa-caret-down:before {
    color: #5B6265;
  }

  .fa {
    margin-left: 5px;
  }
}

.nav .open > a {
  color: #000;
}

.badge {
  color: #FFF;
  background: #F34D0B;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  padding-left: 0;
  padding-right: 0;
  line-height: 18px;
}

.navbar-default .navbar-brand {
  margin: 19px 0 0 23px;
  background: url('../../img/logo_37.png') no-repeat left top;
  padding: 9px 0 0 56px;
  font-family: 'PT Sans', sans-serif;
  text-transform: uppercase;
  color: #FFF;
  font-weight: 500;
  font-size: 15px;
}

.navbar-default.sidebar {
  background: none;
}

.navbar-top-links {
  > li {
    border-right: 1px solid #363D41;

    &:last-child {
      margin: 0;
    }
  }

  li a {
    padding: 27px 17px 23px;
  }
}
