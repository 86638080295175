
.pagination > li > a {
  margin: 0 6px 0 0;
  font-size: 14px;
  color: #2A7AAF;
  font-weight: bold;
  border-color: #E2E2E2;
}

.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span {
  margin: 0 10px 0 0;
  border-radius: 0;
  background: #E7E7E7;
  font-size: 14px;
  color: #5B6265;
  border: none;
  font-weight: normal;
}

.pagination > li:last-child > a, .pagination > li:last-child > span {
  margin: 0 0 0 4px;
}
