body {
  background: #FFF;
}

#wrapper {
  margin-bottom: 20px;
}

.page-header {
  margin: 72px 0 28px 0;
  font-weight: bold;
  font-size: 30px;
  border: none;

  .label {
    vertical-align: middle;
  }
}

.body-container {
  @media (min-width: 768px) {
    display: flex;
  }
}

.body-content {
  flex-grow: 1;
}


.body-topright {
  display: flex;

  @media (min-width: 768px) {
    margin: 72px 0 30px auto;
    padding-left: 30px;
    align-self: flex-start;
  }

  @media (max-width: 768px) {
    margin-top: 30px;
  }

  > a {
    margin-left: 5px;
    margin-bottom: 5px;
  }
}

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between!important;
}

.align-items-baseline {
  align-items: baseline!important;
}

.light-gray {
  color: #d5d5d5;
}