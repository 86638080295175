///**
// * @_links.scss
// * Formatting for links.
// */
.btn {
  border-radius: 0;
  font-size: 16px;
}

.dataTable .btn-sm {
  border: 1px solid #B1B1B1;
  text-transform: uppercase;
  font-size: 11px;
  padding: 3px 4px 3px 4px;
}

.dataTable .btn-sm.no-border {
  border: none;
}

.btn {
  padding: 13px 17px 15px 18px;
  border: none;
}

.btn-warning {
  background: #D5D5D5;
  color: #08151D;

  &:hover {
    background: #EAEAEA;
    color: #08151D;
  }
}

.btn-success {
  background: #6FAF2A;

  i.fa-plus-circle {
    font-size: 18px;
    padding: 17px 11px 18px;
    background: #7FC434;
    margin: 0 10px 0 0;
  }

  &:hover {
    background: #2A7AAF;

    i.fa-plus-circle {
      background: #4792C4;
    }
  }
}

.btn-success.pull-right {
  padding: 0 11px 0 0;
}

#btn-reset {
  padding: 8px 13px;
  background: #08151D;

  .fa-refresh {
    color: #FFF;
  }
}

.btn-file {
  padding: 7px 14px 7px 14px;
}

.btn-default {
  color: #333;
  background-color: #FFF;
  border: 1px solid #CCC;
}

.btn-block {
  margin-top: 5px;
}
