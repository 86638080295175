.occurrence-type-box {
  position: relative;
  vertical-align: middle;
  display: flex;
}

.occurrence-type-box > * {
  border: 1px solid #ddd;
  border-radius: 3px;
  cursor: pointer;
  padding: 10px 16px;
}

.occurrence-type-box > *.active {
  background-color: #f5f5f5;
}

.occurrence-type-box > *:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.occurrence-type-box > *:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}